import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mac from '../../../../assets/images/1-sms/mac.png';
import iphone from '../../../../assets/images/1-sms/iphone.png';
import iphone2 from '../../../../assets/images/1-sms/iphone2.png';

const Mac = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 18}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -85vh;
transform-origin: left center;
left: 6vw;
height: 40vh; 
`;

const IphoneRight = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 8.5}%) scale(0.5)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -132vh;
right: 2vw;
transform-origin: right center;
height: 80vh;
`;

const IphoneLeft = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 3.5}%) scale(0.5)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom: -106vh;
left: 2vw;
transform-origin: left center;
position: absolute;
height: 80vh;
`;

class SmsImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight);
    scrollPercent -= scrollOffsetInPercent;
    return (
      <React.Fragment>
        <Mac src={mac} scroll={scrollPercent} alt="Mac" />
        <IphoneRight src={iphone} scroll={scrollPercent} alt="iPhone" />
        <IphoneLeft src={iphone2} scroll={scrollPercent} alt="iPhone" />
      </React.Fragment>
    );
  }
}

SmsImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default SmsImages;
