import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mac from '../../../../assets/images/2-loan-admin/mac.png';
import ipad from '../../../../assets/images/2-loan-admin/ipad.png';
import iphone from '../../../../assets/images/2-loan-admin/iphone.png';

const Ipad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 15}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom:-80vh;
left: 3vw;
height: 60vh;
`;

const Iphone = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(40px,-${(scroll) * 8}%) scale(0.7)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -110vh;
right: 0vw;
height: 80vh; 
`;

const Mac = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(-40px,-${(scroll) * 13}%) scale(0.99)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom: -180vh;
left: 0vw;
position: absolute;
height: 80vh;
`;

class LoanAdminImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight) + index - 1;
    scrollPercent -= scrollOffsetInPercent;
    return (
      <React.Fragment>
        <Ipad src={ipad} scroll={scrollPercent} alt="iPad" />
        <Iphone src={iphone} scroll={scrollPercent} alt="iPhone" />
        <Mac src={mac} scroll={scrollPercent} alt="Mac" />
      </React.Fragment>
    );
  }
}

LoanAdminImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default LoanAdminImages;
