import React, {Component} from 'react';
import styled from 'styled-components';
import vhCheck from 'vh-check';
import TextContent from './TextContent';
import ImageContent from './ImageContent';

const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    /* border: 1px dashed red; */
`;

class Work extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vh: 0,
            slideNumber: 0,
        };
        this.pageSplitTimes = 1.3;
        this.lastScrollTop = 0;
        this.scrollDirectionDown = true;
        this.handleScroll = this.handleScroll.bind(this);
        this.workDetails = [
            {
                number: '',
                projectName: '',
                projectDesc: '',
                projectType: '',
                roles: [''],
            },
            {
                number: '2022',
                projectName: 'SMS Dashboard',
                projectDesc: '10DLC SMS campaign administration dashboard for the US market & an extensive set of new features for the Admin Portal of a Telecom & CPaaS company.',
                projectType: 'WEB APP (React.js, Node.js, Spring Boot)',
                roles: ['Technical Lead', 'Senior Full-Stack Engineer'],
            },
            {
                number: '2021',
                projectName: 'Fintech Loan-Admin',
                projectDesc: 'Module based on Spring microservices used for managing client loans, installment plans, repayment and penalties tracking.',
                projectType: 'MICROSERVICES (Java 11, Spring Boot)',
                roles: ['Senior Back-End Engineer'],
            },
            {
                number: '2020',
                projectName: 'BPM Automation',
                projectDesc: 'Microservices platform for managing business processes related to the production, shipment and tracking of goods for a U.S. based manufacturing company.',
                projectType: 'WEB APP (Camunda BPM, Java, Spring)',
                roles: ['Technical Lead', 'Senior Back-End Engineer'],
            },
            {
                number: '2019',
                projectName: 'Internet Banking',
                projectDesc: 'Austrian commercial banking web application used by 520K users.',
                projectType: 'WEB APP (Spring, Struts, Grails, React.js)',
                roles: ['Senior Full-Stack Engineer'],
            },
            {
                number: '2018',
                projectName: 'B2B Telecom-Admin',
                projectDesc: 'Corporate telecom administration platform migration to SpringBoot.',
                projectType: 'WEB APP (Java EE, Spring Boot, JMS, JSP)',
                roles: ['Full-Stack Engineer'],
            },
            {
                number: '2017',
                projectName: 'JBoss banking apps migration',
                projectDesc: 'Enterprise back-office banking platforms migration to a more secure application server and a newer JRE.',
                projectType: 'WEB APPS (JBoss EAP, J2EE, Kerberos)',
                roles: ['Full-Stack Engineer'],
            },
            {
                number: '',
                projectName: '',
                projectDesc: '',
                projectType: '',
                roles: [''],
            },
        ];
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        const vhDiff = vhCheck().offset;
        this.setState(
            {
                vh: Math.round(
                    (window.document.documentElement.clientHeight + vhDiff) * this.pageSplitTimes,
                ),
            },
        );
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        const {body, documentElement} = event.srcElement;
        const {vh, slideNumber} = this.state;
        const scrollDistance = Math.max(body.scrollTop, documentElement.scrollTop);
        if (scrollDistance > this.lastScrollTop) {
            this.scrollDirectionDown = true;
        } else {
            this.scrollDirectionDown = false;
        }
        this.lastScrollTop = scrollDistance;

        if (scrollDistance > 700 && scrollDistance < 900 && slideNumber === 0) {
            if (this.scrollDirectionDown === true) {
                window.scrollTo(0, 901);
            } else {
                window.scrollTo(0, 699);
            }
        }

        if (scrollDistance > 600 && scrollDistance < 1100 && slideNumber === 0) {
            if (this.scrollDirectionDown === true) {
                window.scrollTo(0, 1101);
            } else {
                window.scrollTo(0, 599);
            }
        }

        // TODO fix scroll delay for last project
        // if (scrollDistance > 8400 && scrollDistance < 8700 && slideNumber === 7) {
        //     if (this.scrollDirectionDown === true) {
        //         window.scrollTo(0, 8701);
        //     } else {
        //         window.scrollTo(0, 8399);
        //     }
        // }

        if (Math.floor(scrollDistance / vh) !== slideNumber
            && slideNumber < this.workDetails.length - 1) {
            this.setState({slideNumber: Math.floor(scrollDistance / vh)});
        } else if (slideNumber === this.workDetails.length - 1
            && (Math.floor(scrollDistance / vh) < slideNumber)) {
            this.setState({slideNumber: Math.floor(scrollDistance / vh)});
        }
    }

    changeTextContentBasedOnScroll() {
        const {slideNumber} = this.state;
        const refresh = true;
        return (
            <TextContent
                number={this.workDetails[slideNumber].number}
                projectName={this.workDetails[slideNumber].projectName}
                projectDesc={this.workDetails[slideNumber].projectDesc}
                projectType={this.workDetails[slideNumber].projectType}
                roles={this.workDetails[slideNumber].roles}
                refreshToggle={refresh}
            />
        );
    }

    render() {
        return (
            <Container>
                {this.changeTextContentBasedOnScroll()}
                <ImageContent pageSplitTimes={this.pageSplitTimes}/>
            </Container>
        );
    }
}

export default Work;
