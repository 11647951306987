import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mac from '../../../../assets/images/1-sms/mac.png';
import ipad from '../../../../assets/images/1-sms/ipad.png';
import iphone from '../../../../assets/images/1-sms/iphone.png';
import iphone2 from '../../../../assets/images/1-sms/iphone2.png';


const Mac = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 13}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -90vh;
left: 2vw;
height: 90vh;
`;

const IphoneLeft = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 13}%) scale(0.9)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -100vh;
left: 2vw;
height: 65vh;
`;

const IphoneRight = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 7}%) scale(0.9)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom:-55vh;
right: 1vw;
height: 65vh;
`;

const Ipad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 6}%) scale(0.8)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom:-120vh;
left: 2vw;
position: absolute;
height: 75vh;
`;

class SmsImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight);
    scrollPercent -= scrollOffsetInPercent;
    return (
      <React.Fragment>
        <Ipad src={ipad} scroll={scrollPercent} alt="iPad" />
        <IphoneLeft src={iphone2} scroll={scrollPercent} alt="iPhone" />
        <IphoneRight src={iphone} scroll={scrollPercent} alt="iPhone" />
        <Mac src={mac} scroll={scrollPercent} alt="Mac" />
      </React.Fragment>
    );
  }
}

SmsImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default SmsImages;
