import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mac from '../../../../assets/images/5-telecom-admin/mac.png';
import ipad from '../../../../assets/images/5-telecom-admin/ipad.png';
import ipad2 from '../../../../assets/images/5-telecom-admin/ipad2.png';

const Mac = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 20}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -134vh;
left: 1vw;
transform-origin: left center;
height: 42vh;

`;

const Ipad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 16}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -89vh;
right: 3vw;
transform-origin: right center;
height: 27vh;
`;

const Ipad2 = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 14}%)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom: -111vh;
left: 1vw;
transform-origin: left center;
position: absolute;
height: 27vh;
`;

class TelecomAdminImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight);
    scrollPercent -= scrollOffsetInPercent;
    return (
      <React.Fragment>
          <Mac src={mac} scroll={scrollPercent} alt="Mac" />
          <Ipad src={ipad} scroll={scrollPercent} alt="iPad" />
          <Ipad2 src={ipad2} scroll={scrollPercent} alt="iPad" />
      </React.Fragment>
    );
  }
}

TelecomAdminImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default TelecomAdminImages;
