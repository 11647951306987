import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mac from '../../../../assets/images/5-telecom-admin/mac.png';
import ipad from '../../../../assets/images/5-telecom-admin/ipad.png';
import ipad2 from '../../../../assets/images/5-telecom-admin/ipad2.png';

const Mac = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 15}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -154vh;
left: 0vw;
height: 90vh;
`;

const Ipad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(130px,-${(scroll) * 8}%) scale(0.65)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -100vh;
right: 0vw;
height: 80vh;
`;

const Ipad2 = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(-155px,-${(scroll) * 3}%) scale(0.7)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom: -70vh;
left: 0vw;
position: absolute;
height: 75vh;
`;

class TelecomAdminImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight);
    scrollPercent -= scrollOffsetInPercent;
    return (
        <React.Fragment>
          <Mac src={mac} scroll={scrollPercent} alt="Mac" />
          <Ipad src={ipad} scroll={scrollPercent} alt="iPad" />
          <Ipad2 src={ipad2} scroll={scrollPercent} alt="iPad" />
        </React.Fragment>
    );
  }
}

TelecomAdminImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default TelecomAdminImages;
