import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mac from '../../../../assets/images/6-jboss-migration/mac.png';
import ipad from '../../../../assets/images/6-jboss-migration/ipad.png';
import ipad2 from '../../../../assets/images/6-jboss-migration/ipad2.png';
import ipadVertical from '../../../../assets/images/6-jboss-migration/ipad-vertical.png';

const VerticalIpad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 30}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -221vh;
left: 12vw;
transform-origin: left center;
width: 80vw;
`;

const Ipad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 25}%)`,
  }),
})`
position: absolute;
bottom: -112vh;
right: 2vw;
transform-origin: right center;
width: 70vw;
`;

const Ipad2 = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 13}%)`,
  }),
})`
bottom: -85vh;
left: 4vw;
transform-origin: left center;
position: absolute;
width: 70vw;
`;

const Mac = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 5}%)`,
  }),
})`
bottom: -97vh;
right: 3vw;
transform-origin: right center;
position: absolute;
width: 92vw;
`;

class JbossMigrationImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight);
    scrollPercent -= scrollOffsetInPercent;
    return (
      <React.Fragment>
          <VerticalIpad src={ipadVertical} scroll={scrollPercent} alt="Vertical iPad" />
          <Ipad src={ipad} scroll={scrollPercent} alt="iPad" />
          <Ipad2 src={ipad2} scroll={scrollPercent} alt="iPad" />
          <Mac src={mac} scroll={scrollPercent} alt="Mac" />
      </React.Fragment>
    );
  }
}

JbossMigrationImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default JbossMigrationImages;
