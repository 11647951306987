import React, {Component} from 'react';
import styled from 'styled-components';
import twitterImg from '../../../assets/images/social/twitter.svg';
import githubImg from '../../../assets/images/social/git.svg';
import mailImg from '../../../assets/images/social/mail.svg';
import instaImg from '../../../assets/images/social/insta.svg';
import linkedInImg from '../../../assets/images/social/linkedin.svg';
import SocialLogo from './SocialLogo';
import device from '../../../assets/responsive/breakpoints';

const Container = styled.section`
    margin-top: 20vh;
    height: 100vh;
    width:100%;
    /* border: 1px solid blue; */
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    @media ${device.mobileS} {
    }
    @media ${device.mobileM} {
    }
    @media ${device.mobileL} {
    }
    @media ${device.tablet} {
    }
    @media ${device.laptop} {
    }
`;

const ContactTitle = styled.div`
  font-family: 'AvenirHeavy';
  color: #000;
  @media ${device.mobileS} {
    font-size: 40px;
  }
  @media ${device.mobileM} {
    font-size: 50px;
  }
  @media ${device.mobileL} {
    font-size: 60px;
  }
  @media ${device.tablet} {
    font-size: 90px;
  }
  @media ${device.laptop} {
    font-size: 95px;
  }
`;

const SocialMediaIcons = styled.div`
  /* border: 1px solid black; */
  z-index: 1;
  display: grid;
  grid-template: 80px 80px 80px / 1fr 1fr;
  @media ${device.mobileS} {
    margin-top: 60px;
    grid-gap: 40px;
  }
  @media ${device.mobileM} {
    margin-top: 60px;
    grid-gap: 60px;
  }
  @media ${device.mobileL} {
    margin-top: 60px;
    grid-gap: 70px;
  }
  @media ${device.tablet} {
    margin-top: 80px;
    grid-gap: 170px;
  }
  @media ${device.laptop} {
    margin-top: 120px;
    grid-gap: 200px;
  }
`;

class Contact extends Component {
    render() {
        return (
            <Container>
                <ContactTitle>CONTACT</ContactTitle>
                <SocialMediaIcons>
                    <SocialLogo imgURL={mailImg} alternate="Mail"
                                redirectURL="mailto:silviu.g.gheorghe@gmail.com?subject=🤘 Hi Silviu, I have a cool project for you"/>
                    <SocialLogo imgURL={linkedInImg} alternate="Linkedin"
                                redirectURL="https://linkedin.com/in/silviu-gsg"/>
                    <SocialLogo imgURL={githubImg} alternate="Github" redirectURL="https://github.com/silviu-gsg"/>
                    {/*<SocialLogo imgURL={instaImg} alternate="Instagram"*/}
                    {/*            redirectURL="https://www.instagram.com/silviu.gsg/"/>*/}
                    <SocialLogo imgURL={twitterImg} alternate="Twitter" redirectURL="https://twitter.com/silviugsg"/>
                </SocialMediaIcons>
            </Container>
        );
    }
}

export default Contact;
