import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mac from '../../../../assets/images/6-jboss-migration/mac.png';
import ipad from '../../../../assets/images/6-jboss-migration/ipad.png';
import ipad2 from '../../../../assets/images/6-jboss-migration/ipad2.png';
import ipadVertical from '../../../../assets/images/6-jboss-migration/ipad-vertical.png';

const Ipad2 = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 30}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -200vh;
left: 0vw;
height: 50vh; 
`;

const Mac = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 20}%) scale(0.9)`,
  }),
})`
position: absolute;
bottom:-280vh;
right: 0vw;
transform-origin: right center;
height: 80vh;
`;

const Ipad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 10}%) scale(0.99)`,
  }),
})`
bottom:-110vh;
left: 0vw;
position: absolute;
height: 50vh;
`;

const VerticalIpad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 4.5}%) scale(0.8)`,
  }),
})`
bottom:-80vh;
right: 1vw;
transform-origin: right center;
position: absolute;
height: 60vh;
`;

class JbossMigrationImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight);
    scrollPercent -= scrollOffsetInPercent;
    return (
      <React.Fragment>
        <VerticalIpad src={ipadVertical} scroll={scrollPercent} alt="Vertical iPad" />
        <Ipad src={ipad} scroll={scrollPercent} alt="iPad" />
        <Mac src={mac} scroll={scrollPercent} alt="Mac" />
        <Ipad2 src={ipad2} scroll={scrollPercent} alt="iPad" />
      </React.Fragment>
    );
  }
}

JbossMigrationImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default JbossMigrationImages;
