import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import mac from '../../../../assets/images/3-bpm-automation/mac.png';
import iphone from '../../../../assets/images/3-bpm-automation/iphone.png';


const Mac = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 25}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -120vh;
transform-origin: left center;
left: 2vw;
height: 43vh;
`;

const Iphone = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(-95px,-${(scroll) * 5}%) scale(0.45)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -118vh;
transform-origin: right center;
right: 0vw;
height: 100vh;
`;

class BpmAutomationImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight) + index - 1;
    scrollPercent -= scrollOffsetInPercent;

    return (
      <React.Fragment>
          <Mac src={mac} scroll={scrollPercent} alt="Mac" />
          <Iphone src={iphone} scroll={scrollPercent} alt="iPhone" />
      </React.Fragment>
    );
  }
}

BpmAutomationImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default BpmAutomationImages;
