import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ipad from '../../../../assets/images/4-internet-banking/ipad.png';
import ipadVertical from '../../../../assets/images/4-internet-banking/ipad-vertical.png';
import iphone from '../../../../assets/images/4-internet-banking/iphone.png';
import iphone2 from '../../../../assets/images/4-internet-banking/iphone2.png';

const VerticalIpad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(-20px,-${(scroll) * 15}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -82vh;
left: 0vw;
height: 75vh; 
`;

const Iphone = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(40px,-${(scroll) * 9}%) scale(0.7)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -50vh;
right: 0vw;
height: 80vh;
`;

const Iphone2 = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(-90px,-${(scroll) * 7}%) scale(0.7)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom: -100vh;
left: 0vw;
position: absolute;
height: 90vh;
`;

const Ipad = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(145px,-${(scroll) * 4.5}%) scale(0.7)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom: -63vh;
right: 0vw;
position: absolute;
height: 80vh;
`;

class InternetBankingImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heightToBeReducedInVh = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heightToBeReducedInVh) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight) + index - 1;
    scrollPercent -= scrollOffsetInPercent;

    return (
      <React.Fragment>
        <Ipad src={ipad} scroll={scrollPercent} alt="iPad" />
        <Iphone src={iphone} scroll={scrollPercent} alt="iPhone" />
        <Iphone2 src={iphone2} scroll={scrollPercent} alt="Mac" />
        <VerticalIpad src={ipadVertical} scroll={scrollPercent} alt="Vertical iPad" />
      </React.Fragment>
    );
  }
}

InternetBankingImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default InternetBankingImages;
